import React from 'react'
import { MainLayout } from '@cmp/site/'
import { GlobalDataProvider } from '@src/data/global-data'
import { GetFavorites } from '@cmp/sections'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'

export default () => {
  const { language } = useLanguage()
  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout isSnyrtivara>
        <GetFavorites />
      </MainLayout>
    </GlobalDataProvider>
  )
}